import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './portfolio.css';
import { useTranslation } from 'react-i18next';
import ModalImage from 'react-modal-image';

const Portfolio = () => {
  const { t, i18n } = useTranslation();

  const [portfolioData, setPortfolioData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://admin.faiz.cc/api/portfolio');
      setPortfolioData(response.data);
    } catch (error) {
      console.log('Error fetching portfolio data', error);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const filteredData = selectedCategory === 'all' ? portfolioData : portfolioData.filter(item => item.category_id === selectedCategory);
  return (
    <section id="portfolio">
      <h5>{t('My Recent Work')}</h5>
      <h2>{t('Portfolio')}</h2>

      {/* Category filter */}
      <div className="portfolio__categories">
        <button className={selectedCategory === 'all' ? 'active' : ''} onClick={() => handleCategoryChange('all')}>
          {t('All')}
        </button>
        <button className={selectedCategory === '1' ? 'active' : ''} onClick={() => handleCategoryChange('1')}>
          {t('Design')}
        </button>
        <button className={selectedCategory === '2' ? 'active' : ''} onClick={() => handleCategoryChange('4')}>
          {t('Web')}
        </button>
        <button className={selectedCategory === '3' ? 'active' : ''} onClick={() => handleCategoryChange('3')}>
          {t('Ui/Ux')}
        </button>
      </div>

      <div className="container portfolio__container">
      {filteredData.map((item) => (
          <article key={item.id} className="portfolio__item">
            <div className="portfolio__item-image">
              <ModalImage small={`https://admin.faiz.cc/uploads/${item.image}`} large={`https://admin.faiz.cc/uploads/${item.image}`} alt="" />
            </div>
            {item.link !== null ? (
              <div className="portfolio__item-cta">
                <a href={item.link} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                  {t('Live Demo')}
                </a>
              </div>
            ) : null}
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;