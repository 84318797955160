import React from 'react';
import "./footer.css";
import {BsLinkedin} from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'



const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer>
      <ul className='permalinks'>
       <li><a href="#">{t('Home')}</a></li>
       <li><a href="#about">{t('About')}</a></li>
       <li><a href="#education">{t('Education')}</a></li> 
       <li><a href="#experience">{t('Experience')}</a></li>
       <li><a href="#skills">{t('Skills')}</a></li>
       <li><a href="#services">{t('Services')}</a></li>
       <li><a href="#portfolio">{t('Portfolio')}</a></li>
       <li><a href="#contact">{t('Contact')}</a></li>
      </ul>

       <div className="footer__socials">
       <a href="https://www.linkedin.com/in/faiz-ahmed-3aa340180"><BsLinkedin /></a>
       </div>

       <div className="footer__copyright">
        <small>&copy; {t('All right')}</small>
       </div>
    </footer>
  )
}

export default Footer
