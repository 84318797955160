import React from 'react'
import './experience.css'
import { Fade } from 'react-reveal'
import ExperienceCard from './ExperienceCard'
import experienceData from '../../data/experience.json'
import { useTranslation } from 'react-i18next';


const Experience = () => {
  const { t, i18n } = useTranslation();

  return (
      <section id='experience'>
        <h2>{t('Experience')}</h2>
      <div className="experience-content">
        <ul className="experience-list">
          {experienceData.experience.reverse().map((exp) => (
            <li key={`experience-${exp.company}`}>
              <Fade bottom duration={1000} distance="20px">
                <ExperienceCard experience={exp} />
              </Fade>
            </li>
          ))}
        </ul>
        <Fade bottom duration={1200} distance="20px">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
          </div>
        </Fade>
      </div>
      </section>
  )
}

export default Experience
