import React from 'react';
import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Services from './components/services/Services';
import Portfolio from './components/portfolio/Portfolio';
import Education from './components/education/Education';
import Experience from './components/experience/Experience';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import { FloatingWhatsApp } from 'react-floating-whatsapp'


const App = () => {
  return (
    <>

      <Header />
      <Nav />
      <FloatingWhatsApp
       phoneNumber="+249100141854"
       accountName="Faiz"	
       avatar="none" />
      <About />
      <Education />
      <Experience />
      <Skills />
      <Services />
      <Portfolio />
      <Contact />
      <Footer />
     </>
  )
}

export default App