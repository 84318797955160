import React, { useRef } from 'react';
import "./Contact.css";
import {GrMail} from 'react-icons/gr';
import {BiSolidPhoneCall, BiLogoWhatsapp} from 'react-icons/bi';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';


const Contact = () => {
  const { t, i18n } = useTranslation();
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6g4vmm4', 'template_hno60pm', form.current, 'J6cBYszyGnOSC9puR')
      e.target.reset()
      };

  return (
    <section id='contact'>
      <h5>{t('Get In Touch')}</h5> 
      <h2>{t('Contact')}</h2>
      <div className='container contact__container'>
          <div className="contact__options">
          <article className="contact__option">
            <GrMail className="contact__option-icon" />
          <h4>{t('Email')}</h4>
          <h5>{t('Faiz.cn0@gmail.com')}</h5>
          <a href="mailto:faiz.cn0@gmail.com" target='_blank'>{t('Send a message')}</a>
          </article>

          <article className="contact__option">
            <BiSolidPhoneCall className="contact__option-icon"/>
          <h4>{t('Phone')}</h4>
          <h5>{t('+966 5908 312 01')}</h5>
          <a href="tel:+966590831201" target='_blank'>{t('Call me')}</a>
          </article>

          <article className="contact__option">
            <BiLogoWhatsapp className="contact__option-icon" />
          <h4>{t('Whatsapp')}</h4>
          <h5>{t('+966 5908 312 01')}</h5>
          <a href="https://api.whatsapp.com/send?phone=966590831201" target='_blank'>{t('Send a message')}</a>
          </article>
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='name' placeholder='Your Name' required />
            <input type="email" name='email' placeholder='Your Email' required />
            <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
            <button type='submit' className='btn btn-primary'>{t('Send')}</button>

          </form>
        </div>
    </section>
  )
}

export default Contact