import React from 'react'
import {BiLogoLinkedin} from 'react-icons/bi';
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/faiz-ahmed-3aa340180" target='_blank'><BiLogoLinkedin /></a>

    </div>
  )
}

export default HeaderSocials