import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Hello Im": "Hello I'm",
      "Faiz Ahmed": "Faiz Ahmed",
      "Fullstack Developer": "Fullstack Developer",
      "Scroll Down": "Scroll Down",
      "Download CV": "Download CV",
      "Lets Talk": "Let's Talk",
      "Get To Know": "Get To Know",
      "About Me": "About Me",
      "Skills": "Skills",
      "3 Years working": "3+ Years working",
      "Clients": "Clients",
      "Projects": "Projects",
      "80 Completed": "50+ Completed",
      "lorem-ipsum" : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae a odio officiis. Veritatis doloribus ad inventore quo neque nisi dolorum eligendi similique sunt iure? Laborum quaerat vitae dolor vel excepturi.",
      "about-me" : "⚡ I'm a Fullstack Developer focusing on Front-End web and Back-End and Design. ⚡ You can find me working with Wordpress, JavaScript, React, php, Photoshop, Html5, Css3 and Adobe XD. Since 2018 - I've spent my time seeking and learning new technologies and forms of digital expression. This has led to me working on some amazing world-class projects, worked at some amazing places, and worked with some great people.",
      "The skills i have" : "The skills i have",
      "Frontend Development": "Frontend Development",
      "HTML": "HTML",
      "CSS": "CSS",
      "JavaScript": "JavaScript",
      "Experienced": "Experienced",
      "Bootstrap": "Bootstrap",
      "Tailwind": "Tailwind",
      "React js": "React js",
      "Intermediate": "Intermediate",
      "Backend Development": "Backend Development",
      "PHP Laravel": "PHP Laravel",
      "MYSQL": "MYSQL",
      "Cloud Server": "Cloud Server like:AWS,DigitalOcean",
      "Graphic Design": "Graphic Design",
      "Photoshop": "Photoshop",
      "Illustrator": "Illustrator",
      "Figma": "Figma",
      "Web Development": "Web Development",
      "UI/UX Design": "UI/UX Design",
      "Digital Marketing": "Digital Marketing",
      "Services": "Services",
      "What I offer": "What I offer",
      "Get In Touch": "Get In Touch",
      "Contact": "Contact",
      "Email": "Email",
      "Faiz.cn0@gmail.com": "Faiz.cn0@gmail.com",
      "Send a message": "Send a message",
      "Phone": "Phone",
      "+966 5908 312 01": "+966 5908 312 01",
      "Call me": "Call me",
      "Whatsapp": "Whatsapp",
      "Send": "Send",
      "Education": "Education",
      "IS _ Network Management & Implementation": "Information System _ Network Management & Implementation",
      "2014 – 2018": "2014 – 2018",
      "Sudan International University - Khartoum - Sudan (With Grade V.Good)": "Sudan International University - Khartoum, Sudan ",
      "Experience": "Experience",
      "Home": "Home",
      "About": "About",
      "Portfolio": "Portfolio",
      "All right": "Faiz. All right reserved 2023",
      "My Recent Work": "My Recent Work",
      "Live Demo": "Live Demo",
      "All" : "All",
      "Design" : "Design",
      "Web" : "Web",
      "Ui/Ux" : "Ui/Ux",
    }
  },
  ar: {
    translation: {
      "Hello Im": "مرحبا أنا",
      "Faiz Ahmed": "فايز أحمد",
      "Fullstack Developer": "مطور ويب",
      "Scroll Down": "ازل للأسفل",
      "Download CV": "تحميل الـ CV",
      "Lets Talk": "لنتحدث الآن",
      "Get To Know": "هيا بنا",
      "About Me": "عني",
      "Skills": "المهارات",
      "3 Years working": "3+ سنة بالمجال",
      "Clients": "العملاء",
      "Projects": "Projects",
      "80 Completed": "50+ مكتملة",
      "lorem-ipsum" : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae a odio officiis. Veritatis doloribus ad inventore quo neque nisi dolorum eligendi similique sunt iure? Laborum quaerat vitae dolor vel excepturi.",
      "about-me" : "⚡ أنا مطور Fullstack وأركز على الفرونت اند والباك اند والتصميم. ⚡ يمكنك أن تجدني أعمل مع Wordpress وJavaScript وReact وphp وPhotoshop وHtml5 وCss3 وAdobe XD. منذ عام 2018 - قضيت وقتي في البحث عن تقنيات وأشكال جديدة  وتعلمها. وقد دفعني هذا إلى العمل في بعض المشاريع الرائعة ذات المستوى العالمي، والعمل في بعض الأماكن الرائعة، والعمل مع بعض الأشخاص الرائعين.",
      "The skills i have" : "المهارات التي أمتلكها",
      "Frontend Development": "تطوير الواجهات الأمامية",
      "HTML": "HTML",
      "CSS": "CSS",
      "JavaScript": "JavaScript",
      "Experienced": "خبير",
      "Bootstrap": "Bootstrap",
      "Tailwind": "Tailwind",
      "React js": "React js",
      "Intermediate": "متوسط",
      "Backend Development": "Backend Development",
      "PHP Laravel": "PHP Laravel",
      "MYSQL": "MYSQL",
      "Cloud Server": "Cloud Server like:AWS,DigitalOcean",
      "Graphic Design": "التصميم",
      "Photoshop": "Photoshop",
      "Illustrator": "Illustrator",
      "Figma": "Figma",
      "Web Development": "تطوير المواقع",
      "UI/UX Design": "تصميم وتجربة واجهة",
      "Digital Marketing": "التسويق الإلكتروني",
      "Services": "الخدمات",
      "What I offer": "ماذا أقدم",
      "Get In Touch": "احصل على عرض",
      "Contact": "التواصل",
      "Email": "البريد الإلكتروني",
      "Faiz.cn0@gmail.com": "Faiz.cn0@gmail.com",
      "Send a message": "أرسل لي",
      "Phone": "الهاتف",
      "+966 5908 312 01": "+966 5908 312 01",
      "Call me": "اتصل بي",
      "Whatsapp": "واتساب",
      "Send": "أرسل",
      "Education": "التعليم",
      "IS _ Network Management & Implementation": "نظم معلومات _ تصميم وإدارة الشبكات",
      "2014 – 2018": "2014 – 2018",
      "Sudan International University - Khartoum - Sudan (With Grade V.Good)": "جامعة السودان العالمية - الخرطوم - السودان",
      "Experience": "الخبرة",
      "Home": "الرئيسية",
      "About": "عني",
      "Portfolio": "الأعمال",
      "All right": "Faiz. جميع الحقوق محفوظة 2023",
      "My Recent Work": "أخر الأعمال",
      "Live Demo": "عرض",  
      "All" : "الكل",
      "Design" : "التصاميم",
      "Web" : "المواقع الإلكترونية",
      "Ui/Ux" : "تصميم وتجربة المستخدم",  }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;