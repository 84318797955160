import React from 'react';
import  './header.css';
import CTA from './CTA';
import ME from '../../assets/me.png';
import HeaderSocials from './HeaderSocials';
import { useTranslation } from 'react-i18next';


const Header = () => {
   const { t, i18n } = useTranslation();

  return (
     <header>
      <div className="container header__container">
         <h5>{t('Hello Im')}</h5>
         <h1>{t('Faiz Ahmed')}</h1>
         <h5 className='text-light'>{t('Fullstack Developer')}</h5>
         <CTA />
         <HeaderSocials />
         <div className="me">
          <img src={ME} alt="me" />
         </div>
         <a href="#contact" className='scroll__down'>{t('Scroll Down')}</a>
      </div>
     </header>  
    )
}

export default Header