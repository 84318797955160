import React from 'react'
import "./skills.css";
import {BsCheckCircleFill} from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const Skills = () => {
  const { t, i18n } = useTranslation();
  return (
    <section id='experience'>
      <h5>{t('The skills i have')}</h5>
      <h2>{t('Skills')}</h2>

      <div className='container experience__container'>
      <div className='experience__frontend'>
        <h3>{t('Frontend Development')}</h3>
        <div className="experience__content">
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
<div>
<h4>{t('HTML')}</h4>
           <small className='text-light'>{t('Experienced')}</small>
</div>          </article>
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('CSS')}</h4>
           <small className='text-light'>{t('Experienced')}</small>
           </div>
          </article>
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('JavaScript')}</h4>
           <small className='text-light'>{t('Intermediate')}</small>
           </div>
          </article>
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('Bootstrap')}</h4>
           <small className='text-light'>{t('Experienced')}</small>
           </div>
          </article>
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('Tailwind')}</h4>
           <small className='text-light'>{t('Experienced')}</small>
           </div>
          </article>
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('React js')}</h4>
           <small className='text-light'>{t('Experienced')}</small>
           </div>
          </article>
        </div>
        </div>
        <div className='experience__backend'>
        <h3>{t('Backend Development')}</h3>
        <div className="experience__content">
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('PHP Laravel')}</h4>
           <small className='text-light'>{t('Experienced')}</small>
           </div>
          </article>
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('MYSQL')}</h4>
           <small className='text-light'>{t('Experienced')}</small>
           </div>
          </article>
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('Cloud Server')}</h4>
           <small className='text-light'>{t('Intermediate')}</small>
           </div>
          </article>
        </div>
        </div>


        <div className='experience__design'>
        <h3>{t('Graphic Design')}</h3>
        <div className="experience__content">
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('Photoshop')}</h4>
           <small className='text-light'>{t('Experienced')}</small>
           </div>
          </article>
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('Illustrator')}</h4>
           <small className='text-light'>{t('Experienced')}</small>
           </div>
          </article>
          <article className='experience__details'>
           <BsCheckCircleFill className='experience__details-icon' />
           <div>
           <h4>{t('Figma')}</h4>
           <small className='text-light'>{t('Intermediate')}</small>
           </div>
          </article>
        </div>
        </div>

      </div>

    </section>
  )
}

export default Skills