import React from 'react';
import './services.css';
import {BsCheck} from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import UiUx from '../../assets/ui-ux.jpg';
import Web from '../../assets/web-development.jpg';
import Dm from '../../assets/digital-marketing.jpg';
import Gr from '../../assets/graphic-design.jpg';



const Services = () => {
  const { t, i18n } = useTranslation();
  return (
    <section id='services'>
      <h5>{t('What I offer')}</h5>
      <h2>{t('Services')}</h2>
      <div className="container services__container">

      <article className='service__item'>
          <div className="service__item-image"> 
          <img src={Web} alt={t('Web Development')} />
          </div>
          <h3>{t('Web Development')}</h3>
        </article>

        <article className='service__item'>
          <div className="service__item-image"> 
          <img src={UiUx} alt={t('UI/UX Design')} />
          </div>
          <h3>{t('UI/UX Design')}</h3>
        </article>

        <article className='service__item'>
          <div className="service__item-image"> 
          <img src={Gr} alt={t('Graphic Design')} />
          </div>
          <h3>{t('Graphic Design')}</h3>
        </article>

        <article className='service__item'>
          <div className="service__item-image"> 
          <img src={Dm} alt={t('Digital Marketing')} />
          </div>
          <h3>{t('Digital Marketing')}</h3>
        </article>

      </div>
    </section>
  )
}

export default Services