import React from 'react';
import  './nav.css';
import {AiFillHome,AiOutlineUser} from 'react-icons/ai';
import {BiBook} from 'react-icons/bi';
import {RiServiceLine} from 'react-icons/ri';
import {TbMessage2} from 'react-icons/tb';
import {useState} from 'react';
import {PiStudent} from 'react-icons/pi';
import {MdWorkspacesFilled} from 'react-icons/md';
import {HiLanguage} from 'react-icons/hi2';
import { useTranslation } from 'react-i18next';



const Nav = () => {
  const { i18n } = useTranslation();
  const [activeNav, setActiveNav] = useState(i18n.language);

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage);
    setActiveNav(newLanguage);
  };

  // const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><AiFillHome /></a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><AiOutlineUser /></a>
      <a href="#education" onClick={() => setActiveNav('#education')} className={activeNav === '#education' ? 'active' : ''}><PiStudent /></a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><BiBook /></a>
      <a href="#services" onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}><RiServiceLine /></a>
      <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><MdWorkspacesFilled /></a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><TbMessage2 /></a>
      <a onClick={toggleLanguage} className={activeNav === 'ar' ? 'active' : ''}>
      <HiLanguage />
    </a>
      {/* <a onClick={() => {
        i18n.changeLanguage('ar');}} className={activeNav === 'ar' ? 'active' : ''}><HiLanguage /></a> */}
    </nav>
  )
}

export default Nav



