import React from 'react';
import "./about.css";
import ME from '../../assets/me-about.jpg';
import {LiaAwardSolid} from 'react-icons/lia';
import {HiUsers} from 'react-icons/hi';
import {AiOutlineFundProjectionScreen} from 'react-icons/ai';
import { useTranslation } from 'react-i18next';


const About = () => {
  const { t, i18n } = useTranslation();

  return (
    <section id='about'>
      <h5>{t('Get To Know')}</h5>
      <h2>{t('About Me')}</h2>
      <div className='container about__container'>
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="about image" />
          </div>
        </div>
        <div className="about__content">
      <div className="about__cards">
        <article className='about__card'> 
         <LiaAwardSolid  className='about__icon' />
         <h5>{t('Experience')}</h5>
         <small>{t('3 Years working')}</small>
        </article>

        {/* <article className='about__card'> 
         <HiUsers  className='about__icon' />
         <h5>{t('Clients')}</h5>
         <small>{t('Clients')}</small>
        </article> */}

        <article className='about__card'> 
         <AiOutlineFundProjectionScreen  className='about__icon' />
         <h5>{t('Projects')}</h5>
         <small>{t('80 Completed')}</small>
        </article>
      </div>
      <p>{t('about-me')}</p>
      <a href="#contact" className='btn btn-primary'>{t('Lets Talk')}</a>
        </div>
      </div>
    </section>
  )
}

export default About