import React from 'react';
import CV from '../../assets/cv.pdf';
import { useTranslation } from 'react-i18next';


const CTA = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="cta"> 
        <a href={CV} download className='btn'>{t('Download CV')}</a>
        <a href="#contact" className='btn btn-primary'>{t('Lets Talk')}</a>
    </div>
  )
}

export default CTA