import React from 'react';
import './education.css';
import {LiaUniversitySolid} from 'react-icons/lia';
import { useTranslation } from 'react-i18next';


const Education = () => {
    const { t, i18n } = useTranslation();
  return (
    <section id="education">
            <h2>{t('Education')}</h2>


        <div className="container">
            <div className="row">
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <ul class="timeline">
                            <li class="wow fadeInLefttBig" data-wow-duration="1s">
                                <div class="timeline-badge">
                                <i ><LiaUniversitySolid className="education__icon" /></i>
                                </div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4 class="timeline-title">{t('IS _ Network Management & Implementation')}</h4>
                                        <h5>{t('2014 – 2018')}</h5>
                                    </div>
                                    <div class="timeline-body">
                                        <p>{t('Sudan International University - Khartoum - Sudan (With Grade V.Good)')}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Education